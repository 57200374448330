// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // baseUrl: 'http://13.250.242.130:3220/api/v1/en',

      // baseUrl: 'http://54.186.118.166:3937/api/v1/en',
  //  baseUrl: 'https://api.darkcarz.com/api/v1/',
   baseUrl: 'https://stagingapi.darkcarz.com/api/v1/',
  //  baseUrl: 'http://localhost:4000/api/v1/',
  // baseUrl: 'http://3.122.216.37:3200/api/v1/en',

 // baseUrl: 'http://52.57.134.73:ng 3200/api/v1/en',

  // baseUrl: 'https://18.218.141.42:3220/api/v1/en',
  // baseUrl:'https://api.testmustangyellow.com/api/v1/en',

  // baseUrl: 'http://localhost:3200/api/v1/en',
  mapApiKey: 'AIzaSyB3oIJlf7xs1TumYREL9QsuMozE7CAzInw',

  //STRIPEkEY
  stripe: 'pk_test_51LdfDPC4UTHOd9OCPNr0HODyd4PgwazzQGSFy4iw5FiMX6PjfS8XfuWfHFr2EY3EQFKX3NzxqflUqgkTrrmzOQnZ000jOhrhQU',
  socket:'https://stagingapi.darkcarz.com/',
  // socket:'https://api.darkcarz.com/',
  // socket:'http://localhost:4000/'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
